import React from "react";
import * as Styled from "./styles";
import footerImg from "../../images/footer.svg";
import logoWhite from "../../images/logo-white.svg";
import Social from '../social'

const Footer = () => (
  <Styled.Footer>
    <img alt="People Club" src={footerImg} className="wave" />

    <Styled.FooterInner>
      <img
        alt="People Club"
        className="logo"
        src={logoWhite}
        height="32"
        style={{ float: "left" }}
      />
    </Styled.FooterInner>
    <Styled.FooterInner>
      <div>
      <p style={{ marginTop: 50 }}>
          Unit 318 Metal Box Factory, Great Guildford Street, London, England SE1 0HS
        </p>
        <p style={{ opacity: 0.2, position: "absolute", bottom: 20 }}>
          &copy; 2021 People Club
        </p>
      </div>
      <Social />
    </Styled.FooterInner>
  </Styled.Footer>
);

export default Footer;
