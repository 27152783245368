import React from "react";
import Home from './containers/home'
import WorkWithUs from './containers/work-with-us'
import People from './containers/people'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";
import CaseStudies from "./containers/case-studies";

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-000000-01');

export default function App() {
  return (
    <Router>
      <div>
        
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
        <Route path="/home">
            <Home />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/people">
            <People />
          </Route>
          <Route path="/work-with-us">
            <WorkWithUs />
          </Route>
          <Route path="/case-studies">
            <CaseStudies />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Services() {
  return <h2>Services</h2>;
}
