import styled from 'styled-components'

export const Social = styled.div`

display: flex;
color: #fff;
flex-direction: row;
margin-top: 20px;
color: #fff;

li {
    list-style-type: none;
    margin-bottom: 0px;
    padding: 0 0 15px 100px;

    img {
      margin: 0 10px 0 20px;
      padding: 0 0 0 10px;
      top: 5px;
      position: relative;
    }
    a {
      color: #141531;
      text-decoration: none;
    }
  }

@media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    > div {
      padding: 0;
    }

    ul {
      padding: 0;
      margin: 30px 0 50px;

      li {
        padding: 0;
        margin: 0 0 10px;
        img {
          padding: 0;
          margin: 0 10px 0 0;
        }
      }
    }
  }
`