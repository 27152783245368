import styled, { css } from "styled-components";

const Header = styled.header`
  width: 100%;
  height: 400px;
  background-color: #f5f1ff;
  position: relative;
  padding: 40px;
  box-sizing: border-box;
  margin-bottom: 100px;

  .wave {
    position: absolute;
    width: 100%;
    transform: translateY(100%);
    bottom: 2px;
    left: 0;
  }

  .menu {
    float: right;
  }

  nav button {
    @media (max-width: 800px) {
      // display: none;
    }
  }

  .header-image {
    position: relative;
    float: right;
    clear: both;
    z-index: 1;
    // margin-top: 220px;
    max-width: 570px;
    width: 50%;

    @media (max-width: 800px) {
      width: 80%;
      top: 50px;
    }
  }

  @media (max-width: 800px) {
    height: auto;
    padding: 20px;
    padding-bottom: 0;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex: 1;
  clear: both;
  padding-top: 100px;
  justify-content: space-between;

  > div {
    // max-width: 500px;
    padding-right: 200px;
    padding-left: 200px;
    text-align: center;
  }

  @media (max-width: 1000px) {
    padding-top: 80px;
    flex-direction: column;
    align-items: center;
    text-align: center;

    > div {
      padding: 0;
      margin: 10px 0;
      text-align: left;
      //  max-width: 80%;
    }
  }
  @media (max-width: 800px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Heading = styled.div`
  font-size: 40px;
  line-height: 56px;

  @media (max-width: 800px) {
    font-size: 28px;
    line-height: 40px;
  }
`;

const Middle = styled(Heading)`
  // position: absolute;
  // top: 270px;
  // width: 500px;
`;

const Main = styled.main`
  font-family: "DM Sans", sans-serif;
  color: #141531;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    padding: 0;
  }
`;

const Button = styled.button`
outline: none;
  border: 3px solid #141531;
  ${(props) =>
    props.noBorder &&
    css`
      border-color: transparent;
    `}
  border-radius: 10px;
  background-color: transparent;
  font-size: 20px;
  padding: 11px 24px 8px;
  margin-left: 20px;
  a {
    text-decoration: none;
    color: #141531;
  }
`;

const Block = styled.div`
  background-color: #fff;
  overflow: hidden;
  width: 100%;
  padding: 100px 0 0;
  ${(props) =>
    props.background &&
    css`
      background-color: ${props.background};
    `}

  @media (max-width: 800px) {
    overflow: visible;
    padding-top: 20px;
    padding-bottom: 50px;
  }
`;

const Text = styled.div`
  width: 770px;

  margin: auto;
  text-align: center;

  p {
    margin: 20px 0;
    font-size: 24px;
    line-height: 40px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    text-align: left;
    line-height: 20px;
    padding: 0 20px;
    box-sizing: border-box;

    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const BlockHeading = styled(Heading)`
  width: 770px;

  margin: 0 auto 100px auto;
  text-align: center;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 20px 20px 0 20px;
    margin: 0;
    line-height: 40px;
    box-sizing: border-box;
    justify-content: left;
    text-align: left;
  }
`;

const ImageAndText = styled.div`
  width: 800px;
  clear: both;
  margin: auto;
  display: flex;
  // margin-bottom: -50px;

  > img {
    width: 400px;
  }

  div {
    align-self: center;
    padding: 0 0 0 40px;
  }

  ${(props) =>
    props.right &&
    css`
      flex-direction: row-reverse;

      div {
        padding: 0 40px 0 0;
      }
    `}

  @media (max-width: 1000px) {
    div {
      padding: 20px;
    }

    ${(props) =>
      props.testimonials &&
      css`
        img {
          display: none;
        }
      `}

    > img {
      width: 90%;
      margin: auto;
      max-width: 400px;
    }

    &.testimonials .hero {
      display: none;
    }

    width: 100%;

    flex-direction: column;
  }
`;

const Founders = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  margin: 0 auto 50px;

  @media (max-width: 1200px) {
    padding: 20px;
  }

  @media (max-width: 800px) {
    flex-direction: row;
    margin-bottom: 00px;
  }
`;

const Profile = styled.img`
  width: 240px;
  height: 240px;
  margin: 0 20px 0 0;

  @media (max-width: 800px) {
    display: none;
  }
`;

const Info = styled.div`
  h2 {
    margin: 0 0 10px;
  }


  img {
    margin-right: 10px;
  }
  
  .profile {
    display: none;
  }


  a {
    text-decoration: none;
    color: #141531;
  }

  @media (max-width: 800px) {

    display: flex; 
    

    p a b {
      display: none;

    }
    .profile {
      display: block;
      margin-right: 20px;
      margin-bottom: 20px;  
    }
  
  }
`;

const Logos = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin: auto;
  ${props => props.marginTop && `
  margin-top: ${props.marginTop}px;
`}
  li {
    list-style-type: none;
    align-items: center; 
    display: flex;
    margin: 0 20px 0;
    height: 130px;
    img { 
      width: 130px;
    }
  }

  @media (max-width: 1000px) {
    justify-content: flex-start
  }
`


export {
  Header,
  Main,
  Block,
  HeaderContent,
  Inner,
  Button,
  Founders,
  Info,
  Profile,
  Middle,
  Text,
  Heading,
  ImageAndText,
  BlockHeading,
  Logos
};
