import React from "react";

import * as Styled from "./styles";
import { withRouter } from "react-router-dom";
import headerImg from "../../images/header-case-studies.svg";
import karlaLogo from "../../images/gokarla.png";
import prosapientTestimonial from "../../images/prosapienttestimonial.png";
import prosapientLogo from "../../images/prosapientlogo.jpg";
import karlaTestimonial from "../../images/karla.png";
import Nav from "../../components/nav";
import Footer from "../../components/footer";

function People() {

  React.useEffect(() => {
    document.body.style.overflow = 'visible'
  }, [])

  return (
    <div className="App">
      <Styled.Header>
        <Styled.Inner>
          <Nav />
          <Styled.HeaderContent>
            <Styled.Middle>
            Our team has worked with some of the most innovative companies on
            the planet... but don’t just take our word for it!
            </Styled.Middle>
          </Styled.HeaderContent>
        </Styled.Inner>
        <img
          alt="People Club"
          src={headerImg}
          className="wave"
          style={{ zIndex: 0 }}
        />
      </Styled.Header>
      <Styled.Main>
      <Styled.Block>
          {/* <Styled.ImageAndText className="testimonials">
            <img alt="Heycar" className="hero" src={heycar} />
            <div>
              <h2>
                <img alt="Heycar" src={heycarlogo} height="30" />
              </h2>
              <p>
                "I worked with Jason and Mike during the foundation of heycar
                UK. Dealing with them was easy - they're straightforward,
                engaging and communicate well and they did a great job in
                helping us set up the heycar UK team at pace"
              </p>
              <p>
                <b>Mat Moakes</b>
                <br />
                CEO - heycar UK
              </p>
            </div>
          </Styled.ImageAndText> */}

          <Styled.ImageAndText className="testimonials">
            <img alt="Karla" className="hero" src={karlaTestimonial} />
            <div>
              <h2>
                <img alt="Heycar" src={karlaLogo} height="30" />
              </h2>
              <p>
                "People Club helped us 3x the team and scale from 4 to 12 with the best talent perfectly 
                fitting into the company we want to build. Rod did an amazing job helping us 
                get off the ground on the talent side of things; candidate pipelines, profile calibrations, 
                processes... just everything!""
              </p>
              <p>
                <b>Fred Schroeder</b>
                <br />
                Founder - Karla
              </p>
              <p>
                <a target="_blank" rel="noopener noreferrer" href="https://peopleclub.medium.com/a-people-club-case-study-karla-144057a9fe52">Talent Advisory Case Study</a>
              </p>
            </div>
          </Styled.ImageAndText>

          <Styled.ImageAndText className="testimonials" right>
            <img alt="proSapient" className="hero" src={prosapientTestimonial} />
            <div>
              <h2>
                <img alt="proSapient" src={prosapientLogo} height="50" />
              </h2>
              <p>
                "People Club helped us deliver cultural change as we grew from 70 to over 200 people. 
                I really respect consultants that don’t just advise but also deliver 
                real change and that is very much the People Club ethos!"
              </p>
              <p>
                <b>Mike Wroe</b>
                <br />
                Chairman - proSapient
              </p>
              <p>
                <a target="_blank" rel="noopener noreferrer" href="https://peopleclub.medium.com/a-people-club-case-study-prosapient-b83f43d47318">People Advisory Case Study</a>
              </p>
            </div>
          </Styled.ImageAndText>

        </Styled.Block>
        

        <Footer />
      </Styled.Main>
    </div>
  );
}

export default withRouter(People);
