import React from "react";

import * as Styled from "./styles";
import { withRouter } from "react-router-dom";
import headerImg from "../../images/header-roles.svg";
import Nav from "../../components/nav";
import Footer from "../../components/footer";

function WorkWithUs() {

  const [roles, setRoles] = React.useState<Array<{ description: string, title: string, id: string }>>()
  React.useEffect(() => {
    document.body.style.overflow = 'visible'

    const headers: any = {
      "Content-Type": "application/json",
      "X-Hya-e2e-Token": process.env["NEXT_PUBLIC_E2E_TOKEN"],
    };

    fetch(`${window.location.protocol}//app.hya.work/api/v1/admin/pcRoles`, {
      method: "POST",
      headers: new Headers(headers),
      body: JSON.stringify({
        data: {}
      }),
    }).then(response => response.json())
    .then(data => {
      setRoles(data.jobsData)
    }
    );;
  }, [])

  return (
    <div className="App">
      <Styled.Header>
        <Styled.Inner>
          <Nav />
          <Styled.HeaderContent>
            <Styled.Middle>
              <p>
                Help us build the businesses of the future.
              </p>
              <p>At People Club you’ll meet and work with best in class People, Culture, Talent and Recruitment professionals.  </p>

            </Styled.Middle>
          </Styled.HeaderContent>
        </Styled.Inner>
        <img
          alt="People Club"
          src={headerImg}
          className="wave"
          style={{ zIndex: 0 }}
        />
      </Styled.Header>
      <Styled.Block>
        <Styled.Inner>
          <Styled.Text>
            <p>
              Our projects range from advising Start Up CEOs on how to create an amazing culture, to building teams for growing businesses. You’ll have access to the right people, helping shape their thinking and be involved in key decisions as they build their business.
            </p>
            <p>Each of our clients value our input, and we all deeply care about what we do.
              We’ve built detailed Progression Frameworks for every role within People Club, so you’ll be able to see how you can progress from day one, and we offer a super flexible environment.
            </p>
          </Styled.Text>
        </Styled.Inner>
      </Styled.Block>

      <Styled.Main>
        {roles && <Styled.Roles>
          {roles?.map((role) => <Styled.Role key={role.id}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div><h2>{role.title}</h2>
              </div>
              <a href={`https://app.hya.work/apply/${role.id}`}  rel="noopener noreferrer" target="_blank">

                <Styled.Button>
                  Apply
                </Styled.Button>
              </a>

            </div>

            {role.description && <div>
              <span dangerouslySetInnerHTML={{ __html: role.description }} />
            </div>}

          </Styled.Role>)}
        </Styled.Roles>}
        <Footer />
      </Styled.Main>
    </div>
  );
}

export default withRouter(WorkWithUs);
