import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Button = styled.button`
  border: 3px solid #141531;
  outline: none;
  margin-left: 20px;
  
  ${(props) =>
    props.noBorder &&
    css`
      border-color: transparent;
      margin-left: 0px;
    `}
  border-radius: 10px;
  background-color: transparent;
  font-size: 20px;
  padding: 11px 12px 8px 12px;

  a {
    text-decoration: none;
    color: #141531;
  }
`;

export const Logo = styled(Link)`
  z-index: 101;
  position: relative;
`;

export const Nav = styled.div`
.hamburger { 
    display: none;
}
.close {
    display: none;
}


.menu {
    display: block
}

@media (max-width: 800px) {


    .menu {
        display: none
    }
.hamburger { 
    display: block;
}
}


${props => props.showMenu && css`
@media (max-width: 800px) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #fff;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;
    box-sizing: border-box;
    justify-content: space-between;

    button {
      border: none;
      padding-left: 0px;
      margin-left: 0px;
      text-align: left;
      display: block;
    }

    .menu {
        display: block;
        padding-bottom: 50px;
    }

    .close {
        display: block;
        z-index: 102;
        position: relative;
    }

    .hamburger {
        display: none;
    }
  }
`}

  .menu {
    float: right;



  }
`;
