import React from "react";

import * as Styled from "./styles";
import { withRouter } from "react-router-dom";
import headerImg from "../../images/header-people.svg";

// import twitter from "../../images/twitter-dark.svg";
import linkedin from "../../images/linkedin-dark.svg";

import michael from "../../images/people/michael.jpg";
import joseph from "../../images/people/joseph.jpg";
import rod from "../../images/people/rod.jpg";

import bcg from "../../images/people/bcg.png";
import dazn from "../../images/people/dazn.png";
import apple from "../../images/people/apple.jpeg";
import newsuk from "../../images/people/newsuk.png";
// import outsprint from "../../images/people/outsprint.png";
import primer from "../../images/people/primer.png";
import spirable from "../../images/people/spirable.png";
import tesco from "../../images/people/tesco.png";
import worldfirst from "../../images/people/worldfirst.png";

import Nav from "../../components/nav";
import Footer from "../../components/footer";

function People() {

  React.useEffect(() => {
    document.body.style.overflow = 'visible'
  }, [])

  return (
    <div className="App">
      <Styled.Header>
        <Styled.Inner>
          <Nav />
          <Styled.HeaderContent>
            <Styled.Middle>
              Our expert team know how to help build and scale businesses
              globally.
            </Styled.Middle>
          </Styled.HeaderContent>
        </Styled.Inner>
        <img
          alt="People Club"
          src={headerImg}
          className="wave"
          style={{ zIndex: 0 }}
        />
      </Styled.Header>
      <Styled.Main>
        <Styled.Block>
          <Styled.Inner>
  

            <Styled.Founders>
              <div>
                <Styled.Profile src={michael} alt="Michael Curds" />
              </div>

              <div>
                <Styled.Info>
                  <Styled.Profile
                    className="profile"
                    src={michael}
                    alt="Michael Curds"
                  />

                  <div>
                    <h2>Michael Curds</h2>
                    <b>Co-founder</b>

                    <p>
                      <a
                        style={{ display: "flex", alignItems: "center" }}
                        href="https://www.linkedin.com/in/michaelcurds/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt="Michael Curds - Connect on Linkedin"
                          height="28px"
                          width="28px"
                          src={linkedin}
                        />
                        <b>Connect with Michael</b>
                      </a>
                    </p>
                  </div>
                </Styled.Info>
                <p>
                  Mike has held a number of leadership positions with a variety
                  of businesses over the past 20 years and was most recently
                  Chief People Officer for a fast growing London based FinTech.
                </p>
                <p>
                  In 2014 Mike left Apple to join BCG Digital Ventures as their
                  2nd employee in London. Over the next 5 years he built and led
                  the Talent team, growing the DV London office to over 200
                  people. As part of the Exec, he worked closely with corporate
                  partners to help launch and scale new businesses such as
                  heycar and MachineMax.
                </p>
                <p>
                  Over the years Mike has learnt to understand the
                  many challenges facing young and growing businesses, and took
                  the step to set up People Club in order to help.
                </p>
              </div>
            </Styled.Founders>
            <Styled.Founders>
              <div>
                <Styled.Profile src={rod} alt="Rod Black" />
              </div>

              <div>
                <Styled.Info>
                  <Styled.Profile
                    className="profile"
                    src={rod}
                    alt="Rod Black"
                  />
                  <div>
                    <h2>Rod Black</h2>
                    <b>Lead Talent Manager</b>

                    <p>
                      <a
                        style={{ display: "flex", alignItems: "center" }}
                        href="https://www.linkedin.com/in/rod-black-28924786/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt="Rod Black - Connect on Linkedin"
                          height="28px"
                          width="28px"
                          src={linkedin}
                        />
                        <b>Connect with Rod</b>
                      </a>
                    </p>
                  </div>
                </Styled.Info>
                <p>
                Rod has 8 years experience recruiting Strategy &amp; Management Consulting talent across a variety of disciplines such as innovation, digital, growth, brand strategy and new ventures.
                </p>
                <p>
                Most recently, Rod led the Strategy Team at a boutique innovation recruitment firm, where he oversaw strategic talent projects for global innovation consultancies, design agencies, venture builders and boutique strategy houses. Over 5 years, he helped scale the London office and expand the brand into new markets in the US, Europe and Australia. 
                </p>
                <p>
                Rod began his recruitment career  working with tier 1 clients in strategy &amp; ops, people &amp; change and customer &amp; digital.
                </p>
                <p>
                Outside of work, Rod is a keen golfer, cat dad and a self-titled film buff, pretending to be a screenwriter in his spare time.
                </p>
              </div>
            </Styled.Founders>
            <Styled.Founders>
              <div>
                <Styled.Profile src={joseph} alt="Joseph Zeratzion" />
              </div>

              <div>
                <Styled.Info>
                  <Styled.Profile
                    className="profile"
                    src={joseph}
                    alt="Joseph Zeratzion"
                  />
                  <div>
                    <h2>Joseph Zeratzion</h2>
                    <b>Learning &amp; Development Consultant</b>

                    <p>
                      <a
                        style={{ display: "flex", alignItems: "center" }}
                        href="https://www.linkedin.com/in/joseph-zeratzion/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt="Joseph Zeratzion - Connect on Linkedin"
                          height="28px"
                          width="28px"
                          src={linkedin}
                        />
                        <b>Connect with Joseph</b>
                      </a>
                    </p>
                  </div>
                </Styled.Info>
                <p>
                  Joseph has over a decade of experience building and growing Talent Development teams across FinTech, News, Media and Communications.
                </p>
                <p>
                  Previously Joseph led Talent Development for WorldFirst (a subsidiary of Ant Financial) and Grayling Communications across EMEA, and he has worked closely with Exec teams and senior leaders to develop the leadership culture that allows for high performance.
                </p>
                <p>
                  Joseph is a qualified Executive Coach, Master NLP Practitioner and Certified Gallup Strengths Coach, which all play a central role in how he helps teams and leaders develop their capability.
                </p>
                <p>
                  During his time building and growing talent development teams, Joseph also managed a semi-professional football team and played football at international football tournaments in Europe and North America.
                </p>
              </div>
            </Styled.Founders>

          </Styled.Inner>
        </Styled.Block>

        <Styled.Logos>
            <li style={{ width: "100%", padding: 0, justifyContent: "center", fontSize: 20, fontWeight: "bold"}}>
              The experience we bring
            </li>
            <li>
              <img src={bcg} alt="Boston Consulting Group" />
            </li>
            <li>
              <img src={dazn} alt="Dazn" />
            </li>
            <li>
              <img src={apple} alt="Apple" />
            </li>
            <li>
            <img src={worldfirst} alt="World First" />
     
            </li>
            {/* <li>
              <img src={outsprint} alt="Outsprint" />
            </li> */}
            <li>
              <img src={primer} alt="Primer" />
            </li>
            <li>
              <img src={spirable} alt="Spirable" />
            </li>
            <li>
              <img src={tesco} alt="Tesco" />
            </li>
            <li>
            <img src={newsuk} alt="News UK" />
            </li>
        </Styled.Logos>

        <Footer />
      </Styled.Main>
    </div>
  );
}

export default withRouter(People);
