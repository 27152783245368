import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.svg";
import close from "../../images/close.svg";
import hamburger from "../../images/hamburger.svg";
import Social from "../social";
import * as Styled from "./styles";

const Nav = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const toggleMenu = () => {
      console.log("toggleMenu")
    document.body.style.overflow =  showMenu? 'visible': 'hidden';
    setShowMenu(!showMenu)
  }
  return (
    <Styled.Nav showMenu={showMenu}>
      <Styled.Logo to="/new">
        <img
          alt="People Club"
          src={logo}
          height="32"
          style={{ float: "left", marginTop: 10, zIndex: 101 }}
        />
      </Styled.Logo>

      <div className={showMenu ? "show menu" : "menu"}>
        <Styled.Button noBorder>
          <Link to="/people">
            Meet Our People
          </Link>
        </Styled.Button>
        <Styled.Button noBorder>
          <Link to="/case-studies">
            Case Studies
          </Link>
        </Styled.Button>
        <Styled.Button noBorder>
          <Link to="/work-with-us">
            Work With Us
          </Link>
        </Styled.Button>
        <Styled.Button>
          <a href="mailto:hello@peopleclub.co.uk">Talk to us today</a>
        </Styled.Button>

        <img
          onClick={() => toggleMenu()}
          src={close}
          height="20"
          width="20"
          alt="close"
          className="close"
          style={{ position: "absolute", top: 30, right: 30 }}
        />

        {showMenu && <Social theme="light" />}
      </div>

      <img
        onClick={() => toggleMenu()}
        src={hamburger}
        alt="menu"
        height="20"
        width="20"
        className="hamburger"
        style={{ position: "absolute", top: 10, right: 10 }}
      />
    </Styled.Nav>
  );
};

export default Nav;
