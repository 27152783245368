
import React from 'react'
import emailLight from "../../images/email.svg";
import emailDark from "../../images/email-dark.svg";
import twitterLight from "../../images/twitter.svg";
import twitterDark from "../../images/twitter-dark.svg";
import linkedinLight from "../../images/linkedin.svg";
import linkedinDark from "../../images/linkedin-dark.svg";
import mediumLight from "../../images/medium.svg";
import mediumDark from "../../images/medium-dark.svg";
import * as Styled from "./styles"

export const Social = ({ theme }: any) => {
    return <Styled.Social>
      <ul>
        <li>
          <a href="mailto:hello@peopleclub.co.uk">
            <img alt="Email" height="17" src={theme === "light" ? emailDark: emailLight} />
            hello@peopleclub.co.uk
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/people-club/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Linkedin" height="20" src={theme === "light" ? linkedinDark: linkedinLight} />
            People Club
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/PeopleClubHQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Twitter" height="20"  src={theme === "light" ? twitterDark: twitterLight} />
            @PeopleClubHQ
          </a>
        </li>
        <li>
          <a
            href="https://peopleclub.medium.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Medium" height="20"  src={theme === "light" ? mediumDark: mediumLight} />
            peopleclub.medium
          </a>
        </li>

      </ul>
      </Styled.Social>
  };

  export default Social