
import styled from "styled-components";

const Inner = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    padding: 0;
  }
`;

const Footer = styled.footer`
  width: 100%;
  // height: 300px;
  background-color: #141531;
  position: relative;
  padding: 40px;
  box-sizing: border-box;
  margin-top: 100px;

  @media (max-width: 1000px) {
    margin-top: 10px;
  }

  .wave {
    position: absolute;
    width: 100%;
    transform: translateY(-100%);
    top: 2px;
    left: 0;
  }
`;

const FooterInner = styled(Inner)`
  display: flex;
  color: #fff;
  flex-direction: row;
  margin-top: 20px;
  color: #fff;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding-right: 100px;

    p {
      margin-bottom: 5px;
    }

    img {
      margin-bottom: 20px;
    }
  }

  li {
    list-style-type: none;
    margin-bottom: 0px;
    padding: 0 0 15px 100px;

    img {
      margin: 0 10px 0 20px;
      padding: 0 0 0 10px;
      top: 5px;
      position: relative;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    > div {
      padding: 0;
    }

    ul {
      padding: 0;
      margin: 30px 0 50px;

      li {
        padding: 0;
        margin: 0 0 10px;
        img {
          padding: 0;
          margin: 0 10px 0 0;
        }
      }
    }
  }
`;

export {
  Inner,
  Footer,
  FooterInner,
};
