import React from "react";
import { withRouter } from "react-router-dom";
import headerImg from "../../images/header.svg";
import hero from "../../images/hero.svg";
import people from "../../images/people.svg";
import place from "../../images/place.svg";
import purpose from "../../images/purpose.svg";
import bankable from "../../images/bankable.png";
import cervest from "../../images/cervest.png";
import cl35 from "../../images/cl35.jpg";
import evee from "../../images/evee.png";
import gokarla from "../../images/gokarla.png";
import daslab from "../../images/daslab.png";
import bain from "../../images/bain.png";
import creandum from "../../images/creandum.png";
import smedvifcapital from "../../images/smedvifcapital.webp";
import bp from "../../images/bp.png";
import shareback from "../../images/shareback.png";

import heydoc from "../../images/heydoc.png";
// import mm from "../../images/machinemax.png";
import titanbay from "../../images/titanbay.png";
import fnatic from "../../images/fnatic.png";
import prosapient from "../../images/prosapient.png";
import zapp from "../../images/zapp.png";
import machinemaxlogo from "../../images/machinemaxlogo.png";
import bibliu from "../../images/bibliu.png";
import zeit from "../../images/zeit.png";
import mcf from "../../images/mcf.png";

import Nav from "../../components/nav";
import Footer from "../../components/footer";

import * as Styled from "./styles";

function Home() {

  React.useEffect(() => {
    document.body.style.overflow = 'visible'
  }, [])

  return (
    <div className="App">
      <Styled.Header>
        <Styled.Inner>
          <Nav />
          <Styled.HeaderContent>
            <Styled.Middle>
              Put simply, we are people experts who know how to build and scale
              businesses.
            </Styled.Middle>

            <img alt="People Club" src={hero} className="header-image" />
          </Styled.HeaderContent>
        </Styled.Inner>
        <img
          alt="People Club"
          src={headerImg}
          className="wave"
          style={{ zIndex: 0 }}
        />
      </Styled.Header>
      <Styled.Main>
        <Styled.Block>
          <Styled.Inner>
            <Styled.Text>
              <p>
                People Club support growing companies in creating environments
                that nurture and develop talent to realise their full potential.
              </p>
              <p>
                Whether you’re an entrepreneur just starting out, a growing
                business with new funding or a larger corporate looking to be
                different - we can help ensure that you have the right people
                and strategy in place for your business to have the greatest
                chance of success.
              </p>
            </Styled.Text>
          </Styled.Inner>
        </Styled.Block>
        <Styled.Block background={"#f5f4f9"}>
          <Styled.BlockHeading>
            To create a great workplace you need to get the fundamentals right,
            that's why we focus on three things:
          </Styled.BlockHeading>

          <Styled.ImageAndText>
            <img alt="People Advisory" src={people} />
            <div>
              <h2>People Advisory</h2>
              <p>
                Using our unparalleled knowledge and experience of
                building and scaling teams globally, we’re able to
                reduce key risks as our clients grow.
              </p>
            </div>
          </Styled.ImageAndText>

          <Styled.ImageAndText right>
            <img alt="Talent Advisory" src={place} />
            <div>
              <h2>Talent Advisory</h2>
              <p>
                Our Talent Partners have worked with some of the
                biggest &amp; fastest growing businesses on the planet,
                and know how to find great talent.
              </p>
            </div>
          </Styled.ImageAndText>

          <Styled.ImageAndText>
            <img alt="Purpose" src={purpose} />
            <div>
              <h2>Leaders as a Service</h2>
              <p>
                Our Leadership team has over 50 years experience and
                have lead People, Talent, L&amp;D and HR teams
                across a number of industries.
              </p>
            </div>
          </Styled.ImageAndText>
        </Styled.Block>
       

          <Styled.Logos>
          <li style={{ width: "100%", padding: 0, justifyContent: "center", fontSize: 20, fontWeight: "bold"}}>
              Trusted By
            </li>
            <li>
              <a href="https://www.bnkbl.com/" target="_blank" rel="noopener noreferrer"><img src={bankable} alt="Bankable" /></a>
            </li>
            <li>
              <a href="https://cervest.earth/" target="_blank" rel="noopener noreferrer"><img src={cervest} alt="Cervest" /></a>
            </li>
            <li>
              <a href="https://www.classthirtyfive.com/" target="_blank" rel="noopener noreferrer"><img src={cl35} alt="Class35" /></a>
            </li>
            <li>
              <a href="https://ev.energy/" target="_blank" rel="noopener noreferrer"><img src={evee} alt="Eve Energy" /></a>
            </li>
            <li>
              <a href="https://www.prosapient.com/" target="_blank" rel="noopener noreferrer"><img src={prosapient} alt="Prosapient" /></a>
            </li> 
            <li>
              <a href="https://tryzapp.com/" target="_blank" rel="noopener noreferrer"><img src={zapp} alt="Tryzapp" /></a>
            </li>
            <li>
              <a href="https://www.titanbay.com/" target="_blank" rel="noopener noreferrer"><img src={titanbay} alt="Titanbay" /></a>
            </li>
            <li>
              <a href="https://fnatic.com/" target="_blank" rel="noopener noreferrer"><img src={fnatic} alt="Fnatic" /></a>
            </li>
            <li>
              <a href="https://shareback.io" target="_blank" rel="noopener noreferrer"><img src={shareback} alt="Shareback" /></a>
            </li>
            <li>
              <a href="https://www.heydoc.co.uk/" target="_blank" rel="noopener noreferrer"><img src={heydoc} alt="Zapp" /></a>
            </li>
            <li>
              <a href="https://bibliu.com/" target="_blank" rel="noopener noreferrer"><img src={bibliu} alt="Bibliu" /></a>
            </li>
            <li>
              <a href="https://zeit.app" target="_blank" rel="noopener noreferrer"><img src={zeit} alt="Zeit" /></a>
            </li>
            <li>
              <a href="https://www.gokarla.io/" target="_blank" rel="noopener noreferrer"><img src={gokarla} alt="Go Karla" /></a>
            </li>
            <li>
              <a href="https://daslab.health/" target="_blank" rel="noopener noreferrer"><img src={daslab} alt="Daslab" /></a>
            </li>
            <li>
              <a href="https://www.bain.com/" target="_blank" rel="noopener noreferrer"><img src={bain} alt="Bain" /></a>
            </li>
            <li>
              <a href="https://www.mycommunityfinance.co.uk/" target="_blank" rel="noopener noreferrer"><img src={mcf} alt="My Community Finance" /></a>
            </li>
            <li>
              <a href="https://www.creandum.com/" target="_blank" rel="noopener noreferrer"><img src={creandum} alt="Creandum" /></a>
            </li>
            <li>
              <a href="https://smedvigcapital.com/" target="_blank" rel="noopener noreferrer"><img src={smedvifcapital} alt="Smedvig Capital" /></a>
            </li>
            <li>
              <a href="https://bplaunchpad.com/" target="_blank" rel="noopener noreferrer"><img src={bp} alt="BP Launchpad" /></a>
            </li>
            <li>
              <a href="https://machinemax.com/" target="_blank" rel="noopener noreferrer"><img src={machinemaxlogo} alt="Machinemax" /></a>
            </li>
          </Styled.Logos>

        <Footer />
      </Styled.Main>
    </div>
  );
}

export default withRouter(Home);
